import {defaultTo} from "lodash";

export const DEFAULT_NAMESPACE = "Figured";

function getNamespace(namespace = null) {
    if (!namespace || String(namespace).length === 0) {
        return DEFAULT_NAMESPACE;
    }

    return `${DEFAULT_NAMESPACE}.${namespace}`;
}

/**
 * @param key
 * @param value
 * @param namespace
 */
export function setItem(key, value, namespace = null)
{
    namespace = getNamespace(namespace);

    return localStorage.setItem(`${namespace}.${key}`, value);
}

export function getItem(key, defaultValue = null, namespace = "")
{
    namespace = getNamespace(namespace);

    return defaultTo(localStorage.getItem(`${namespace}.${key}`), defaultValue);
}
